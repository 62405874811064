// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

.index section.section-02-index {
    background: url(../images/bg_paralax_accueil.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
}

section.section-02-index {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-height: 550px;
        transition: .5s;
        div.center-box {
            width: 55%;
            margin: 15px;
            height: 100%;
            min-width: 950px;
            min-height: 35vw;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background: url(../images/bg_zone_accueil.jpg) center center;
            //background-size: cover;
            padding: 5vw 8vw;
            margin-top: 0;
            transition: .5s;
            .side-logo {
                position: absolute;
                right: 2%;
                max-width: 12%;
            }
            @media screen and (max-width: $size-max-2) {
                min-height: 45vw;
                transition: .5s;

                @media screen and (max-width: $size-max-3) {
                    width: 65%;
                    height: 100%;
                    min-width: 80%;
                    min-height: 530px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        justify-content: flex-end;
                        transition: .5s;
                        @media screen and (max-width: 639px) {
                            .side-logo {
                                position: relative;
                                max-width: 30%;
                            }
                            min-height: 640px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                min-width: 300px;
                                min-height: 710px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }

            h3, p {
                font-family: $font-family-3;
                color: $color-black;
                transition: .5s;
                width: 100%;
            }
            h3 {
                font-size: 38px;
                @media screen and (max-width: $size-max-2) {
                    font-size: 2.4vw;
                    line-height: 1.8vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 28px;
                        line-height: 22px;
                        transition: .5s;
                        @media screen and (max-width: 639px) {
                            font-size: 25px;
                            transition: .5s;
                        }
                    }
                }
            }
            p {
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                margin-top: 20px;
                @media screen and (max-width: $size-max-2) {
                    font-size: 1.7vw;
                    line-height: 2.4vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 20px;
                        line-height: 22px;
                        transition: .5s;
                        @media screen and (max-width: 639px) {
                            font-size: 18px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
    &.centerized {
        div.container-fluid.row {
            padding: 0 0;
            min-height: auto;
            div.center-box {
                h3 {
                    text-align: center;
                }
                p {
                    text-align: center;
                }
            }
            div.center-box {
                width: 100%;
                margin: 15px;
                height: 100%;
                min-width: 950px;
                min-height: 22vw;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background: url(../images/bg_zone_accueil.jpg) center center;
                //background-size: cover;
                padding: 5vw 24vw;
                padding-top: 10vw;
                margin-bottom: 0;
                transition: .5s;
                margin-top: 0;
                @media screen and (max-width: $size-max-2) {
                    min-height: 28vw;
                    padding: 5vw 18vw;
                    padding-top: 15vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        //width: 65%;
                        height: 100%;
                        min-width: 80%;
                        //min-height: 530px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            min-height: 465px;
                            justify-content: flex-end;
                            transition: .5s;
                            @media screen and (max-width: 639px) {
                                min-height: 475px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    min-width: 400px;
                                    min-height: 465px;
                                    padding: 25px 10vw;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.without-burst {
        div.container-fluid.row {
            div.center-box {
                width: 100%;
                margin: 15px;
                height: 100%;
                min-width: 950px;
                min-height: 22vw;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background: url(../images/bg_zone_accueil.jpg) center center;
                //background-size: cover;
                padding: 5vw 24vw;
                //padding-top: 10vw;
                margin-bottom: 0;
                transition: .5s;
                margin-top: 0;
                @media screen and (max-width: $size-max-2) {
                    min-height: 28vw;
                    padding: 5vw 18vw;
                    //padding-top: 15vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        //width: 65%;
                        height: 100%;
                        min-width: 80%;
                        //min-height: 530px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            min-height: 465px;
                            justify-content: flex-end;
                            transition: .5s;
                            @media screen and (max-width: 639px) {
                                min-height: 475px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    min-width: 400px;
                                    min-height: 465px;
                                    padding: 25px 10vw;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-03-index {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        padding: 0;
        @media screen and (max-width: $size-max-m-2) {
            flex-direction: column;
            transition: .5s;
        }
        div.left-section-03,
        div.right-section-03 {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                min-height: 39vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
            }
        }
        div.left-section-03 {
            div.content-img-section-03 {
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    height: 100%;
                    transition: .5s;
                }
                img {
                    width: 100%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        height: 100%;
                        transition: .5s;
                    }
                }
            }
        }
        div.right-section-03 {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: url(../images/bg_zone_accueil.jpg) center center;
            transition: .5s;
            padding: 0 5vw;
            @media screen and (max-width: $size-max-m-2) {
                justify-content: center;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    padding: 45px 0;
                    transition: .5s;
                }
            }
            ul.list-specialites {
                width: 100%;
                list-style: none;
                list-style-type: none;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 10vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        margin-left: 2vw;
                        transition: .5s;
                    }
                }
                h3.title-list-section-03 {
                    font-family: $font-family-3;
                    text-transform: none;
                    color: $color-black;
                    margin-bottom: 15px;
                    font-size: 2.2vw;
                    font-weight: 800;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 2.5vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 25px;
                            line-height: 25px;
                            font-weight: 500;
                            transition: .5s;
                        }
                    }
                }
                li.item {
                    display: flex;
                    font-family: $font-family-3;
                    align-items: center;
                    padding-left: 3vw;
                    line-height: 50px;
                    transition: .5s;
                    span {
                        color: $color-black;
                        text-transform: none;
                        font-size: 30px;
                        @media screen and (max-width: $size-max-1) {
                            font-size: 1.5vw;
                            line-height: 2.4vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 1.9vw;
                                line-height: 2.0vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 15px;
                                    line-height: 20px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 18px;
                                        line-height: 25px;
                                        font-weight: 500;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                    img {
                        padding-right: 20px;
                        @media screen and (max-width: $size-max-m-1) {
                           height: 2vw;
                            transition: .5s;
                        }
                    }
                }
            }
            div.content-img-bottom {
                height: 48%;
                display: flex;
                align-items: flex-end;
                padding-bottom: 0;
                position: absolute;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    padding-bottom: 2vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        padding-bottom: 4vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            padding-bottom: 8vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                position: relative;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    margin-top: 0;
                                    transition: .5s;
                                    position: absolute;
                                    padding-right: 5vw;
                                    right: 0;
                                    @media screen and (max-width: $size-max-m-4) {
                                        padding-bottom: 15vw;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
                img {
                    transition: .5s;
                    bottom: 0;
                    @media screen and (max-width: $size-max-1) {
                        height: 6vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            height: 8vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                height: 14vw;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }
    &.reverse-section {
        div.container-fluid.row {
            flex-direction: row-reverse;
            @media screen and (max-width: $size-max-m-2) {
                flex-direction: column-reverse;
            }
        }
    }
    &.pneus-page {
        background: url(../images/piecesetpneus_section3_bg.jpg) no-repeat center center;
        background-size: cover;
        div.container-fluid.row {
            padding: 2vw 3vw;
            div.left-section-03 {
                display: flex;
                align-items: center;
                justify-content: center;
                div.content-img-section-03 {
                    transition: .5s;
                    img {
                        width: 85%;
                        transition: .5s;
                    }
                }
            }
            div.right-section-03 {
                background-size: 0 0;
                background: transparent;
                padding: 0 0vw;
                ul.list-specialites {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        padding: 65px;
                        transition: .5s;
                    }
                    div.left-list,
                    div.right-list {
                        width: 50%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 100%;
                            transition: .5s;
                        }
                        li.item {
                            padding: 0;
                            line-height: 1.4vw;
                            margin-bottom: 1.2vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-1) {
                                margin-bottom: .8vw;
                                @media screen and (max-width: $size-max-3) {
                                    margin-bottom: .8vw;
                                }
                            }
                            img {
                                padding-right: 10px;
                                transition: .5s;
                            }
                            span {
                                @media screen and (max-width: $size-max-1) {
                                    line-height: 1.4vw;
                                    @media screen and (max-width: $size-max-3) {
                                        line-height: 1.4vw;
                                        @media screen and (max-width: $size-max-m-1) {
                                            font-size: 18px;
                                            line-height: 22px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.recuperation section.section-03-index div.container-fluid.row div.right-section-03 {
    background-image : linear-gradient(0, #e5e5e5, white, #e5e5e5);
}

.pieces section.section-02-index,
.conteneurs section.section-02-index,
.recuperation section.section-02-index {
    .center-box h3 {
        text-transform: initial;
    }
}

.conteneurs section.section-02-index.centerized div.container-fluid.row div.center-box,
.pieces section.section-02-index.centerized div.container-fluid.row div.center-box,
.recuperation section.section-02-index.centerized div.container-fluid.row div.center-box {
    background-image: linear-gradient(0, #dedede, white, #dedede);
}

.conteneurs div.section-icon-bursts div.row div.icon-bursts div.row div.icon-burst a div.element-burst div.text-bloc,
.pieces div.section-icon-bursts div.row div.icon-bursts div.row div.icon-burst a div.element-burst div.text-bloc,
.recuperation div.section-icon-bursts div.row div.icon-bursts div.row div.icon-burst a div.element-burst div.text-bloc {
    justify-content: flex-end;
}

.conteneurs .recuperation section.section-03-index div.container-fluid.row div.right-section-03 ul.list-specialites h3.title-list-section-03,
.pieces .recuperation section.section-03-index div.container-fluid.row div.right-section-03 ul.list-specialites h3.title-list-section-03,
.recuperation section.section-03-index div.container-fluid.row div.right-section-03 ul.list-specialites h3.title-list-section-03 {
    font-weight: 500;
}
.conteneurs section.section-03-index div.container-fluid.row div.right-section-03 ul.list-specialites li.item,
.pieces section.section-03-index div.container-fluid.row div.right-section-03 ul.list-specialites li.item,
.recuperation section.section-03-index div.container-fluid.row div.right-section-03 ul.list-specialites li.item {
    font-weight: 500;
}


section.section-04-recup {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/recuperation_section3_bg.jpg) no-repeat center center;
    background-size: cover;
    transition: .5s;
    div.containr-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 250px;
        padding: 5vw 15px;
        transition: .5s;
        h3.title-header-04-recup {
            width: 100%;
            text-shadow: -1px -1px 10px rgb(0, 0, 0), 1px 1px 5px rgb(0, 0, 0);
            text-align: center;
            font-size: 2.5vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 3.0vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    font-size: 22px;
                    transition: .5s;
                }
            }
        }
        div.content-paragraph {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 15px 15vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                padding: 15px 15px;
                transition: .5s;
            }
            p {
                width: 100%;
                color: $color-black;
                text-align: center;
                line-height: 2.4vw;
                font-size: 1.8vw;
                transition: .5s;
                font-weight: 500;
                @media screen and (max-width: $size-max-m-1) {
                    line-height: 2.8vw;
                    font-size: 2.2vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        line-height: 22px;
                        font-size: 16px;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

section.section-03-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8vw 10px;
        flex-wrap: wrap;
        transition: .5s;
        div.content-logo {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 8px;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                width: 12%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 15%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 20%;
                        transition: .5s;
                    }
                }
            }
            img {
                min-width: 100px;
                transition: .5s;
            }
        }
    }
}

section.section-03-conteneurs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 65px 15px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            justify-content: center;
            flex-wrap: wrap;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                padding: 15px 15px;
                transition: .5s;
            }
        }
        div.box-content {
            width: 25%;
            display: flex;
            align-items: center;
            flex-direction: column;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                width: 30%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 45%;
                    // min-width: 300px;
                    margin: 15px 10px;
                    margin-top: 45px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        width: 100%;
                        min-width: 300px;
                        margin: 15px 10px;
                        margin-top: 45px;
                        transition: .5s;
                    }
                }
            }
            div.content-img-conteneur {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
            }
            div.content-description {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background: $color-5;
                padding: 35px 15px;
                margin-top: 25px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    margin-top: 0;
                    transition: .5s;
                }
                h3.title-conteneur {
                    width: 100%;
                    font-size: 1.8vw;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 8px;
                    text-shadow: -1px -1px 10px rgb(0, 0, 0), 1px 1px 5px rgb(0, 0, 0);
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 24px;
                        transition: .5s;
                    }
                }
                span {
                    width: 100%;
                    font-size: 1.2vw;
                    font-weight: 600;
                    color: $color-black;
                    text-align: center;
                    line-height: 1.4vw;
                    margin-bottom: .4vw;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 18px;
                        line-height: 24px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 16px;
                            line-height: 20px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}

.prix {
    .plus-box {
        background-color: $color-white;
        border: 1px solid $color-black;
        border-radius: 3px;
        margin: $ratio5;
        padding: $ratio5;
        a {
            color: $color-black;
            i {
                margin-right: $ratio3;
                color: $color-4;
                transition: 0.2s all;
            }
            h4 {
                @extend .text-uppercase;
                font-weight: bold;
                font-size: $font-size-32;
                margin: 0 0;
            }
        }
        a[aria-expanded="true"] {
            i {
                transform: rotate(45deg);
                transition: 0.2s all;
            }
            h4 {

            }
        }
        .collapse .well, .collapsing .well {
            background-color: $color-white;
            border: none;
            box-shadow: none;
            table {
                th {
                    background-color: $color-2;
                    p {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .name {
            p {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .price {
            p {
                color: $color-4;
                span {
                    color: $color-black;
                }
            }
        }
        .modification p, .unit p, .specification p {
            font-weight: normal;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .plus-box {
            margin: $ratio5 0;
            .separator {
                background-color: $color-2;
                height: 25px;
                margin: 0 -2px 10px -2px;
            }
            .name p, .price p, .specification p {
                margin-bottom: $ratio1;
            }
            .specification p, .modification p {
                font-size: $font-size-18;
            }
            .collapse .well, .collapsing .well {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}


section.section-01-prix {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/prixdesmetaux_section2_bg.jpg) repeat-y;
    background-size: cover;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 45px 15px;
        transition: .5s;
        div.top-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 45px 0px;
            transition: .5s;
            h3 {
                width: 100%;
                text-shadow: -1px -1px 10px rgb(0, 0, 0), 1px 1px 5px rgb(0, 0, 0);
                text-align: center;
                font-size: 2.5vw;
                margin-bottom: 5px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    font-size: 3.0vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        font-size: 22px;
                        transition: .5s;
                    }
                }
            }
            p {
                width: 100%;
                font-weight: 400;
                color: $color-black;
                text-align: center;
                line-height: 2.4vw;
                font-size: 1.5vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    line-height: 2.8vw;
                    font-size: 2.2vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        line-height: 22px;
                        font-size: 16px;
                        transition: .5s;
                    }
                }
            }
        }
        div.bottom-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            transition: .5s;

        }

        .accordion-container {
            width: 85%;
            position: relative;
            border: 2px solid $color-black;
            max-width: 100%;
            height: auto;
            margin: 10px auto;
        }
        .accordion-container > h2{
            text-align: center;
            color: #fff;
            padding-bottom: 5px;
            margin-bottom: 20px;
            padding-bottom: 15px;
            border-bottom: 0 solid #ddd;
        }
        .set{
            position: relative;
            width: 100%;
            height: auto;
            background-color: transparent;
            &:nth-last-child(1) {
                a {
                    border-bottom: 0px solid $color-black;
                }
            }
        }
        .set > a {
            display: block;
            font-size: 1.2vw;
            padding: 1.5vw 15px;
            text-decoration: none;
            color: $color-black;
            font-weight: 500;
            border-bottom: 2px solid $color-black;
            -webkit-transition:all 0.2s linear;
            -moz-transition:all 0.2s linear;
            transition: all 0.5s linear;
            cursor: pointer;
        }
        .set > a i {
            float: left;
            margin-top: 0px;
            margin-right: 10px;
            transition: .5s;
        }
        .set > a.active > i {
            transform: rotate(45deg);
        }
        .set > a.active {
            background-color:$color-white;
            color: $color-black;
            transition: .5s;

        }
        .content{
            background-color: #fff;
            border-bottom: 1px solid $color-black;
            display:none;
        }
        .content p {
            margin: 0;
            padding: 10px 15px;
            color: $color-black;
        }
    }
    @media screen and (max-width: $size-max-m-1) {
         background-size: contain;
    }
}

.red {
    color: $color-4;
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* DEMANDE PAGE
*/

.formulaire {
    section.header {

    }
    main {
        section.section-form {
            background: url("../images/prixdesmetaux_section2_bg.jpg");
            .entete {
                h2 {
                    @extend .mb8;
                }
            }
            form {
                @media screen and (max-width: $size-xs-max) {
                    .last2 {
                        flex-direction: column;
                    }
                }
            }
        }
    }
    .panel {
        background-color: transparent;
        background-image: none;
        border-color: $color-black;
        border-radius: 3px;
        border-width: 2px;
    }
}

.coordonnees {
    .top-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 45px 0px;
        transition: .5s;
        h3 {
            text-transform: initial;
            width: 100%;
            text-shadow: -1px -1px 10px rgb(0, 0, 0), 1px 1px 5px rgb(0, 0, 0);
            text-align: center;
            font-size: 2.5vw;
            margin-bottom: 5px;
            color: $color-2;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                font-size: 3.0vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    font-size: 22px;
                    transition: .5s;
                }
            }
        }
        p {
            width: 100%;
            font-weight: 400;
            color: $color-black;
            text-align: center;
            line-height: 2.4vw;
            font-size: 1.5vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                line-height: 2.8vw;
                font-size: 2.2vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    line-height: 22px;
                    font-size: 16px;
                    transition: .5s;
                }
            }
        }
    }
    .info {
        h3 {
            transition: .5s;
            width: 100%;
            color: $color-black;
            font-family: $font-family-3;
            font-size: 38px;
            @media screen and (max-width: $size-max-2) {
                font-size: 2.4vw;
                line-height: 1.8vw;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 28px;
                    line-height: 22px;
                    transition: .5s;
                    @media screen and (max-width: 639px) {
                        font-size: 25px;
                        transition: .5s;
                    }
                }
            }
        }
        p, address, a {
            font-family: $font-family-1;
            font-size: $font-size-24;
            font-weight: 500;
            line-height: $font-size-32;
            margin-top: $ratio4;
            color: $color-black;
            @media screen and (max-width: $size-max-2) {
                font-size: 1.7vw;
                line-height: 2.4vw;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 20px;
                    line-height: 22px;
                    transition: .5s;
                    @media screen and (max-width: 639px) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
            }
        }
        a:hover {
            color: $color-1;
        }
        address {
            margin: 0;
        }
    }
    .left {
        div.logo {
            span {
                font-size: 2.2vw;
                padding-left: 10px;
                font-weight: 800;
                transition: all .5s ease;
            }
        }
        div.contact-info {

        }
    }
    .right {
        align-self: flex-end;
        p {
            margin: 0;
        }
        .heures {
            p {
                @extend .flex;
                @extend .justify-between;
            }
        }
    }
    /* purgecss start ignore */
    #map-canvas {
        h3 {
            color: $color-black;
        }
    }
    /* purgecss end ignore */
    @media screen and (max-width: $size-md-max) {
        .left {
            div.logo {
                span {
                    font-size: 3.2vw;
                }
            }
        }
        .right {
            padding-left: $col2;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .info {
            flex-direction: column;
            .right {
                padding-left: 0;
                max-width: 400px;
            }
            div.contact-info {
                margin-bottom: $ratio8;
            }
        }
        .left {
            div.logo {
                span {
                    font-size: 4.2vw;
                }
            }
        }
    }
}
/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
