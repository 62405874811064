div.section-icon-bursts {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    //min-height: 450px;
    margin-top: 0;
    height: 100%;
    width: 100%;
    div.row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: absolute;
        height: 100%;
        z-index: 99;
        width: 92%;
        top: -1vw;
        margin: 0;
        @media screen and (max-width: $size-max-2) {
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                top: -1.222vw;
                width: 98%;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    width: 100%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        top: -1.522vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            top: -2.122vw;
                            transition: .5s;
                        }
                    }
                }
            }
        }
        div.icon-bursts {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 0;
            div.row {
                @media screen and (max-width: $size-max-m-2) {
                    //flex-direction: column;
                    flex-wrap: wrap;
                    transition: .5s;
                }
                .col-xs-12, .col-sm-4 {
                    padding: 0!important;
                    margin: 0;
                }
                div.icon-burst {
                    padding: 0!important;
                    margin: 0 1.5vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 45%;
                        min-width: 300px;
                        transition: .5s;
                    }

                    &:nth-child(1) {
                        a {
                            div.text-bloc {
                                width: 95%;
                                height: 90%;
                            }
                        }
                    }

                    a, div.no-link {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 100%;
                        }
                        div.element-burst {
                            @media screen and (max-width: $size-max-m-2) {
                                width: 100%;
                            }
                        }
                        div.content-img {
                            @media screen and (max-width: $size-max-m-2) {
                                width: 100%;
                            }
                            img {
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 100%;
                                }
                            }
                        }

                        &:hover {
                            div.element-burst {
                                div.content-img {
                                    img {
                                        // transition: .5s;
                                        &:nth-child(1) {
                                            opacity: 0;
                                        }
                                        &:nth-child(2) {
                                            position: absolute;
                                            opacity: 1;
                                        }
                                    }
                                }
                                div.text-bloc {
                                    // h3, h4 {
                                    //     color: $color-2;
                                    //     transition: .5s;
                                    //     text-shadow: 2px 2px 5px $color-shadow,
                                    //         -2px -2px 5px $color-shadow,
                                    //         2px -2px 5px $color-shadow,
                                    //         -2px 2px 5px $color-shadow;
                                    // }
                                }
                            }
                        }


                        div.element-burst {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            //padding: 15px;

                            div.content-img {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: .5s;
                                img {
                                    // width: 95%;
                                    height: 10vw;
                                    // transition: .5s;
                                    &:nth-child(1) {
                                        opacity: 1;
                                    }
                                    &:nth-child(2) {
                                        position: absolute;
                                        opacity: 0;
                                    }
                                    @media screen and (max-width: $size-max-3) {
                                        height: 12vw;
                                        @media screen and (max-width: $size-max-m-2) {
                                            height: 15vw;
                                            @media screen and (max-width: 639px) {
                                                height: 100px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }

                            div.text-bloc {
                                width: 95%;
                                height: 90%;
                                position: absolute;
                                border: 0 solid $color-white;
                                flex-direction: column-reverse;
                                justify-content: center;
                                align-items: center;
                                display: flex;
                                h3 {
                                    font-family: $font-family-3;
                                    color: $color-black;
                                    text-align: center;
                                    font-weight: 800;
                                    font-size: 1.6vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-3) {
                                        font-size: 1.9vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 2.6vw;
                                            transition: .5s;
                                            @media screen and (max-width: 639px) {
                                                font-size: 20px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                                h4 {
                                    font-family: $font-family-3;
                                    color: $color-black;
                                    text-transform: none;
                                    text-align: center;
                                    font-weight: 600;
                                    font-size: 1.5vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-3) {
                                        font-size: 1.8vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 2.5vw;
                                            transition: .5s;
                                            @media screen and (max-width: 639px) {
                                                font-size: 18px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }

                                div.content-icons {
                                    transition: .5s;
                                    img {
                                        margin-top: .5vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-2) {
                                            height: 4vw;
                                            transition: .5s;
                                            @media screen and (max-width: 639px) {
                                                height: 35px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div.no-link:hover {
                        div.element-burst {
                            div.content-img {
                                img {
                                    opacity: 1;
                                    &:nth-child(1) {
                                        opacity: 1;
                                    }
                                    &:nth-child(2) {
                                        position: absolute;
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
