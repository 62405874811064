.slideshow-wrap {
    position: relative;
    background-color: $bg-color-body;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        div.row.text {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            transition: .5s;
            div.top-captions,
            div.middle-captions,
            div.bootom-captions {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
                h3 {
                    text-transform: none;
                    font-weight: 700;
                    font-size: 2.4vw;
                    transition: .5s;
                    text-shadow: 2px 2px 5px $color-shadow,
                             -2px -2px 5px $color-shadow,
                             2px -2px 5px $color-shadow,
                             -2px 2px 5px $color-shadow;
                    span {
                        text-transform: uppercase;
                    }
                    @media screen and (max-width: $size-max-m-4) {
                        font-size: 5.8vw;
                        transition: .5s;
                    }
                }
            }
            div.top-captions {
                @media screen and (max-width: $size-max-2) {
                    margin-top: 8vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        margin-top: 0;
                        transition: .5s;
                    }
                }
            }
            div.middle-captions {               
                img {
                    transition: .5s;
                    @media screen and (max-width: $size-max-1) {
                        height: 18vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            height: 42vw;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: $size-nav-height-mobile + 20px;
        }

    }
}

.ls-gui-element.ls-bottom-nav-wrapper {
    display: none;
}

